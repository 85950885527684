import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enum'
})
export class EnumPipe implements PipeTransform {

  transform(value: unknown, enumSrcType: any, enumDesType: any): unknown {
    const enumValue = Object.entries(enumSrcType).find(x => x && x[0] == value)?.[1];
    return Object.entries(enumDesType).find(x => x && x[0] == enumValue)?.[1] || '';
  }

}
