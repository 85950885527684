import { Pipe, PipeTransform } from '@angular/core';
import * as currency from 'currency.js'

@Pipe({
  name: 'numberFormat',
})
export class FormatNumber implements PipeTransform {
  constructor() {}
  transform(value: number, digits = 2) {
    return Number(value.toPrecision(digits));
  }
}
