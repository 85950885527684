import { Pipe, PipeTransform } from '@angular/core';
import currency from 'currency.js';

@Pipe({
    name: 'formatNumber',
  })
  export class FormatNumberStr implements PipeTransform {
    constructor() {}
    transform(value, digits = 2) {
      const number = currency(value, {
        symbol: '',
        precision: digits,
        separator: ',',
        decimal: '.',
      })
      return number.format()
    }
  }
